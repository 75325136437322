import { createSlice } from "@reduxjs/toolkit";
import axiosInstance from "../../../utils/axiosInstance";
import axios from "axios";
import { setNotification } from "../notification";
import { setOpenBackdrop } from "../backdrop";
import dayjs from "dayjs";
import thousandSeparator from "../../../utils/thousandSeparator";
import enviroments from "../../../utils/enviroments";

const initialState = {
  fundAccounting: [],
  categories: [],
  detailCategories: [],
  anticipo: [],
  pagoRendicion: [],
};

const fundAccounting = createSlice({
  name: "fundAccounting",
  initialState,
  reducers: {
    setFundAccounting: (state, action) => {
      state.fundAccounting = action.payload;
      state.status = "succeeded";
    },
    setCategories: (state, action) => {
      state.categories = action.payload;
      state.status = "succeeded";
    },
    setDetailCategories: (state, action) => {
      state.detailCategories = action.payload;
      state.status = "succeeded";
    },
    addFundAccounting: (state, action) => {
      state.fundAccounting.push(action.payload);
      state.status = "succeeded";
    },
    createDetail: (state, action) => {
      state.detailCategories.push(action.payload);
    },
    updateDetail: (state, action) => {
      const updated = state.detailCategories.filter((el) => el.id !== action.payload.id);
      state.detailCategories = [...updated, action.payload];
    },
    removeDetail: (state, action) => {
      const updated = state.detailCategories.filter((el) => el.id !== action.payload);
      state.detailCategories = updated;
    },
    removeFund: (state, action) => {
      const upated = state.fundAccounting.filter((data) => data.id !== action.payload.id);
      state.fundAccounting = upated;
      state.status = "Eliminado con exito";
    },
    set: (state, { payload: { data, name } }) => {
      state[name] = data;
      state.success = true;
    },
    add: (state, { payload: { data, name } }) => {
      state[name].push(data);
      state.success = true;
    },
    update: (state, { payload: { data, name } }) => {
      state[name] = [...state[name].filter((worker) => worker.id !== data.id), data];
      state.success = true;
    },
    remove: (state, { payload: { name, data } }) => {
      const updated = state[name].filter((el) => el.id !== data);
      state[name] = updated;
    },
  },
});

export const createFundAccounting = (data) => async (dispatch) => {
  try {
    dispatch(setOpenBackdrop(true));
    await axiosInstance.post("/fund-accounting", data);
    dispatch(setOpenBackdrop(false));
    dispatch(
      setNotification({
        status: "success",
        message: "Rendicion creada con exito",
        open: true,
      })
    );
  } catch (error) {
    dispatch(setOpenBackdrop(false));
    dispatch(
      setNotification({
        status: "error",
        message: "Error al crear rendición",
        open: true,
      })
    );
    console.log(error);
  }
};

export const updateFundAccounting = (data) => async (dispatch) => {
  try {
    dispatch(setOpenBackdrop(true));
    await axiosInstance.put("/fund-accounting", data);
    dispatch(setOpenBackdrop(false));
    dispatch(
      setNotification({
        status: "success",
        message: "Rendicion editada con exito",
        open: true,
      })
    );
  } catch (error) {
    dispatch(setOpenBackdrop(false));
    dispatch(
      setNotification({
        status: "error",
        message: "Error al editar rendición",
        open: true,
      })
    );
    console.log(error);
  }
};

export const createFundAndExpense = (val) => async (dispatch) => {
  const { data } = await axiosInstance.post("/fund-accounting/fund-expense", val);
  return data;
};

export const updateExpense = (val) => async () => {
  const { data } = await axiosInstance.put("/fund-accounting/fund-expense", val);
  return data;
};

export const createExpenseWithFund = (val) => async () => {
  const { data } = await axiosInstance.put(`/fund-accounting/fund-expense/${val.fundId}`, val);
  return data;
};

export const updateFundAccountingFields = (val) => async () => {
  try {
    const { data } = await axiosInstance.put(`/fund-accounting/fund/${val.fundId}`, val);
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const deleteExpense = (id) => async () => {
  try {
    const { data } = await axiosInstance.delete(`/fund-accounting/fund-expense/${id}`);
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const updatePendingButton = (val) => async () => {
  try {
    const { data, status } = await axiosInstance.put("/fund-accounting/fund-expense-pending", val);
    return status;
  } catch (error) {
    console.log(error);
  }
};

export const getAllFundAccountings = (id) => async (dispatch) => {
  try {
    const res = await axiosInstance.get(`/fund-accounting?id=${id}`);

    const newData = res.data.map((data) => {
      return {
        ...data,
        rendicion_id: data.id,
        moneda_text: data.moneda.nombre,
        estado_text: data.rendicion_estado.nombre,
        responsable: data.usuario.nombre,
        fecha_formatted: dayjs(data.fecha_rendicion.split("T")[0]).format("DD-MM-YYYY"),
        date: data.fecha_rendicion,
        solicitante: data.usuario.nombre,
        total: thousandSeparator(
          data.rendicion_detalle.reduce(
            (acc, obj) => acc + (!obj.monto ? 0 : parseInt(obj.monto)),

            0
          )
        ),
      };
    });

    dispatch(setFundAccounting(newData));
  } catch (error) {
    console.log(error);
  }
};

export const getPagoRendicion = () => async (dispatch) => {
  try {
    const res = await axiosInstance.get(`/fund-accounting/pago-rendicion`);

    const newData = res.data.map((data) => {
      return {
        ...data,
        rendicion_id: data.id,
        moneda_text: data.moneda.nombre,
        estado_text: data.pagada ? "PAGADA" : "NO PAGADA",
        responsable: data.usuario.nombre,
        fecha_formatted: dayjs(data.fecha_rendicion.split("T")[0]).format("DD-MM-YYYY"),
        date: data.fecha_rendicion,
        total: thousandSeparator(
          data.rendicion_detalle.reduce(
            (acc, obj) => acc + (!obj.monto ? 0 : parseInt(obj.monto)),

            0
          )
        ),
      };
    });

    dispatch(set({ name: "pagoRendicion", data: newData }));
  } catch (error) {
    console.log(error);
  }
};

export const updatePagoRendicion = (val) => async (dispatch) => {
  try {
    const { data, status } = await axiosInstance.put("/fund-accounting/pago-rendicion", val);

    const format = {
      ...data,
      rendicion_id: data.id,
      moneda_text: data.moneda.nombre,
      estado_text: data.pagada ? "PAGADA" : "NO PAGADA",
      responsable: data.usuario.nombre,
      fecha_formatted: dayjs(data.fecha_rendicion.split("T")[0]).format("DD-MM-YYYY"),
      date: data.fecha_rendicion,
      total: thousandSeparator(
        data.rendicion_detalle.reduce(
          (acc, obj) => acc + (!obj.monto ? 0 : parseInt(obj.monto)),

          0
        )
      ),
    };

    dispatch(update({ name: "pagoRendicion", data: format }));
  } catch (error) {
    dispatch(
      setNotification({
        status: "error",
        message: "Error al actualizar anticipo",
        open: true,
      })
    );
    console.log(error);
  }
};

export const resetPagoRendicion = (val) => async (dispatch) => {
  try {
    const { data, status } = await axiosInstance.put("/fund-accounting/reset-pago-rendicion", val);

    const format = {
      ...data,
      rendicion_id: data.id,
      moneda_text: data.moneda.nombre,
      estado_text: data.pagada ? "PAGADA" : "NO PAGADA",
      responsable: data.usuario.nombre,
      fecha_formatted: dayjs(data.fecha_rendicion.split("T")[0]).format("DD-MM-YYYY"),
      date: data.fecha_rendicion,
      total: thousandSeparator(
        data.rendicion_detalle.reduce(
          (acc, obj) => acc + (!obj.monto ? 0 : parseInt(obj.monto)),

          0
        )
      ),
    };

    dispatch(update({ name: "pagoRendicion", data: format }));
  } catch (error) {
    dispatch(
      setNotification({
        status: "error",
        message: "Error al actualizar anticipo",
        open: true,
      })
    );
    console.log(error);
  }
};

export const getAllFundAccountingsVB = (id) => async (dispatch) => {
  try {
    const res = await axiosInstance.get(`/fund-accounting/all`);

    const newData = res.data.map((data) => {
      return {
        ...data,
        rendicion_id: data.id,
        moneda_text: data.moneda.nombre,
        estado_text: data.rendicion_estado.nombre,
        responsable: data.usuario.nombre,
        fecha_formatted: dayjs(data.fecha_rendicion.split("T")[0]).format("DD-MM-YYYY"),
        date: data.fecha_rendicion,
        solicitante: data.usuario.nombre,
        total: thousandSeparator(
          data.rendicion_detalle.reduce(
            (acc, obj) => acc + (!obj.monto ? 0 : parseInt(obj.monto)),

            0
          )
        ),
      };
    });

    dispatch(setFundAccounting(newData));
  } catch (error) {
    console.log(error);
  }
};

export const getExpenseClaimCategories = () => async (dispatch) => {
  try {
    const res = await axiosInstance.post("/fund-accounting/categories");
    dispatch(setCategories(res.data));
  } catch (error) {
    console.log(error);
  }
};

export const getCategoriesDetail = () => async (dispatch) => {
  try {
    const res = await axiosInstance.get("/fund-accounting/categories/detail");
    dispatch(setDetailCategories(res.data));
  } catch (error) {
    console.log(error);
  }
};

export const createCategoriesDetail = (val) => async (dispatch) => {
  try {
    const { data } = await axiosInstance.post("/fund-accounting/categories/detail", val);
    dispatch(createDetail(data));
  } catch (error) {
    dispatch(
      setNotification({
        status: "error",
        message: "Error al crear categoria",
        open: true,
      })
    );
    console.log(error);
  }
};

export const updateCategoriesDetail = (val) => async (dispatch) => {
  try {
    const { data } = await axiosInstance.put("/fund-accounting/categories/detail", val);
    dispatch(updateDetail(data));
  } catch (error) {
    dispatch(
      setNotification({
        status: "error",
        message: "Error al editar categoria",
        open: true,
      })
    );
    console.log(error);
  }
};

export const deleteCategoriesDetail = (id) => async (dispatch) => {
  try {
    const { data } = await axiosInstance.delete(`/fund-accounting/categories/detail?id=${id}`);
    dispatch(removeDetail(data.id));
  } catch (error) {
    dispatch(
      setNotification({
        status: "error",
        message: "Error al eliminar categoria",
        open: true,
      })
    );
    console.log(error);
  }
};

export const deleteFundAccounting = (id) => async (dispatch) => {
  try {
    const deleted = await axiosInstance.delete(`/fund-accounting?id=${id}`);
    dispatch(removeFund(deleted.data));
    dispatch(
      setNotification({
        status: "success",
        message: "Rendicion eliminada con exito",
        open: true,
      })
    );
  } catch (error) {
    dispatch(
      setNotification({
        status: "error",
        message: "Error al eliminar rendición",
        open: true,
      })
    );
    return error.response.data.error || "Error al eliminar";
  }
};

export const createAnticipo = (val) => async (dispatch) => {
  try {
    const { data, status } = await axiosInstance.post("/fund-accounting/anticipo", val);

    const format = {
      ...data,
      bancoText: data.rendicion_gastos?.bancos?.nombre || "EFECTIVO",
      monedaText: data.rendicion_gastos.moneda.nombre,
      rendicionId: data.rendicion_gastos.id,
      name: data?.gen_usuario?.nombre || "S/D",
      devolucion: thousandSeparator(data.rendicion_gastos.devolucion),
      anticipo: thousandSeparator(data.anticipo),
    };
    dispatch(add({ name: "anticipo", data: format }));
    return { data, status };
  } catch (error) {
    dispatch(
      setNotification({
        status: "error",
        message: "Error al crear anticipo",
        open: true,
      })
    );
    console.log(error);
  }
};

export const getAnticipos = (val) => async (dispatch) => {
  try {
    const { data, status } = await axiosInstance.get("/fund-accounting/anticipo");

    const format = data.map((el) => ({
      ...el,
      bancoText: el.rendicion_gastos?.bancos?.nombre || "EFECTIVO",
      monedaText: el.rendicion_gastos.moneda.nombre,
      rendicionId: el.rendicion_gastos.id,
      name: el?.gen_usuario?.nombre || "S/D",
      devolucion: thousandSeparator(el.rendicion_gastos.devolucion),
      anticipo: thousandSeparator(el.anticipo),
    }));

    dispatch(set({ name: "anticipo", data: format }));
    return { data, status: format };
  } catch (error) {
    dispatch(
      setNotification({
        status: "error",
        message: "Error al traer anticipo",
        open: true,
      })
    );
    console.log(error);
  }
};

export const updatePaymentMethod = (val) => async (dispatch) => {
  try {
    const { data, status } = await axiosInstance.put("/fund-accounting/anticipo-pago", val);

    const format = {
      ...data,
      bancoText: data.rendicion_gastos?.bancos?.nombre || "EFECTIVO",
      monedaText: data.rendicion_gastos.moneda.nombre,
      rendicionId: data.rendicion_gastos.id,
      name: data?.gen_usuario?.nombre || "S/D",
      devolucion: thousandSeparator(data.rendicion_gastos.devolucion),
    };
    dispatch(update({ name: "anticipo", data: format }));
  } catch (error) {
    dispatch(
      setNotification({
        status: "error",
        message: "Error al actualizar anticipo",
        open: true,
      })
    );
    console.log(error);
  }
};

export const {
  setFundAccounting,
  setCategories,
  addFundAccounting,
  removeFund,
  setDetailCategories,
  createDetail,
  updateDetail,
  removeDetail,
  set,
  add,
  remove,
  update,
} = fundAccounting.actions;

export default fundAccounting.reducer;
